import React, { useState, useRef, useEffect } from 'react'
import "../../styles/AddLawer2Styles.css"
import axios from "axios";
import GetUrl from "../../ApiCall/GetUrl";

export default function Especialidades() {
  const [espec, setEspecialidadesArr] = useState([]);
  const [presciosArr, setPreciosArr] = useState([]);

  //console.log( "Solicitando especialidades ...")

  useEffect(() => {
    
    
      axios.get(GetUrl() + "get_lawyer_precios").then(response => {
        setPreciosArr(response.data);
        console.log("Precios Este es el response.data: ", response.data);
        getEspecialidades()
      }).catch(err => {
        setPreciosArr(["Hubo un error al traer los datos"]); 
      })
    


    

  } , [])

  function getEspecialidades()
  {
      axios.get(GetUrl() + "get_lawyer_especialidades").then(response => {
        console.log("Especialidades Este es el response.data: ", response.data);
        setEspecialidadesArr( response.data);
        
      }).catch(err => {
        console.log( "Eerror en la especialidad")
        console.log( err )
        //setEspecialidadesArr(["Hubo un error al traer la información"]);
      })
  }

  return (
    <div className='row g-0 mt-4'>

      <div className='col-sm-6'>
        <div className="form-group  "   >
          <label className={"estilo_label"} htmlFor="especialidades">¿Cual(es) son tus especialidades? </label>
          <select placeholder="" type="text" className={"form-control border-0 border-bottom estilo_input"} id="especialidades" title='Nombre de Abogado'

          >
          <option></option>
          {espec.map(v => 
              <option key={v.id_especialidad} value={v.id_especialidad}>{v.especialidad_name}</option>
              
            )}
          </select> 

        </div>
      </div>

      <div className='col-sm-6'>
        <div className="form-group"   >
          <label className={"estilo_label derecho"} style={{ paddingLeft: "22px" }} htmlFor="precio">¿Cuál es el precio promedio de tu hora?</label>
          <select placeholder="" type="email" className={"form-control  border-0 border-bottom border-start estilo_input derecho"} id="precio" title='Nombre de Abogado'
          >
            
            {presciosArr.map(v => 
              <option value={v.id_rango} key={v.id_rango}>{v.rango}</option>
            )}
          </select>

        </div>
      </div>

    </div>

  )
}
