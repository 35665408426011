import React, { useState, useRef, useEffect } from 'react'
import select from 'react-select'
import { Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import PasswordInput from './PasswordInput';

import Carrera from './Carrera';
import Servicios from './Servicios';
import Especialidades from './Especialidades';
import Estados from './Estados';

import Footer from '../Footer/Footer';

import { useForm } from "react-hook-form";
// import './styles.css'
import "../../styles/AddLawer2Styles.css"
import FadeIn from './FadeInLawyer.js';
import axios from "axios";

import GetUrl from "../../ApiCall/GetUrl";
import LoadPhoto from './LoadPhoto.js';
import Fechas from './Fechas.js';
import Languages from './Languages.js';


export default function AddLawer2() {

  const [showPassword, setShowPassword] = useState(false);
  //const [nombre,setNombre] = useState("")
  //const [correo,setCorreo] = useState("")
  //const [telefono,setTelefono] = useState("")
  //const [password,setPassword] = useState("") // Esta pendiente password
  const [descripcion, setDescripcion] = useState("")
  const [despacho, setDespacho] = useState("")
  const [posicion, setPosicion] = useState("")
  const [ubicacion, setUbicacion] = useState("")
  const [matricula, setMatricula] = useState("")

  const [theLoadPhoto,setTheLoadPhoto] = useState( false ) // Por default no se pone la carga de la photo
  const [image,setImage] = useState( null ) ;
  const [languages,setLanguages] = useState([])

  function setTheLoadPhotoPrev( val )
  {
    setTheLoadPhoto( val )

    console.log( "Se programa timer")

    setTimeout( () =>{

      setTheLoadPhoto( false ) ;

    } , 10000 )

  }

  

  /*
{
  "lawyer_email": "test1@gmail.com", // correo
  "lawyer_password": "A1234$", // password
  "lawyer_name": "test one", // nombre
  "lawyer_birthday": "2024-08-12", // Falta *********
  "lawyer_photograph": "aaaaaaaaaaaaaaaaaaaaaaaa",
  "lawyer_status": 1,
  "lawyer_matricula": "aaaaa",
  "lawyer_begin": "2024-08-12",
  "lawyer_working_begin_date": "2024-08-12",
  "lawyer_services": 1,
  "lawyer_cv": "asjfasfsdfaf", // nulo
  "lawyer_linkedin": "sdfasdfasf", // nulo 
  "lawyer_description": "asfahsfahfasdfj",
  "lawyer_rfc": "afajsfasf",
  "lawyer_status_legaltek": 1,
  "id_lawyer_costo": 5,
  "lawer_phone": "fñasdjfkalsdjf", // telefono ( opcional )
  "languages": [
    1,
    2
  ],
  "especialidades": [
    1,
    2,
    3
  ],
  "grado_academico": [
    {
      "id_academic_degree": 1,
      "date_degree": "2024-08-13",
      "grado_academico": 1
    }
  ],
// Todo este JSON es opcional solo en caso de que trabaje en un despacho ¿
  "despacho": {
    "name_despacho": "ajñdflajfl",
    "location": "ajdñkljalfkja",
    "id_position": 1 // 
  }
}


  */

  function onError( errors )
  {
      console.log( "onError " , errors)

      if ( image === null )
      {
        console.log( "No se ha cargado la imagen")
      }
  }

  useEffect(() =>{

    console.log( "Cambio de imagen")

  } ,[image])

  // register, handleSubmit, clearErrors, control, formState: { errors }
  // Actualizador para mostrar errores
  useEffect(() => {
    console.log("Reinicio para errores")
    document.getElementById("finalizar")
    setTimeout(clearErrors, 10);
  }, [])

  const [posicionArr, setPosicionArr] = useState([]);
  useEffect(() => {
    axios.get(GetUrl() + "get_lawyer_position").then(response => {
      setPosicionArr(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
      setPosicionArr(["Hubo un error al obtener la información"])
    });
  }, [])



  const {
    register,
    formState: { errors },
    handleSubmit,
    //setError ,
    watch,
    clearErrors,
    control
  } = useForm(/*{
        defaultValues: {
          legal: { 
            nombre : "" , 
            correo: "" ,
 
            
          } 
        }
      }*/);


  console.log(watch('correo'));


  function handleSub(e) // Lo recortamos para evitar redundancia
  {

    //e.preventDefault() ;
    console.log("handleSub");

    if ( image === null )
    {
      console.log( "No se ha cargado la imagen") 
    }
    else{
      console.log( "Si tenemos la imagen cargada")
    }

  }



  // const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  //   };
  return (
    <>
      <div style={{ height: "100%" }}>
        <div>
          <FadeIn></FadeIn>
        </div>
        <div className='box-login ' id="theDiv" style={{ zIndex: 20, width: '100%', backgroundColor: '#Ffffff', boxShadow: "none" }} >

          <div className='d-flex flex-column align-items-center'
            style={{ borderColor: 'black', fontFamily: 'Satoshi-Bold', overflowX: 'auto', }}>


            <div style={{
              height: '400px', width: '100%',
              backgroundColor: '#86868B', color: 'white'
            }}>

              <h2 className='mt-3 ' style={{ fontSize: "77px", textAlign: "left", paddingTop: "20px", padding: "30px", fontWeight: "700" }}>
                <span style={{ fontStyle: 'bold' }}>Regístrate</span> </h2>

              <div className='mt-4 text-center' >
                <p style={{ fontSize: "60px", fontWeight: "100", textAlign: "left", paddingLeft: "30px" }}>Y recibe tus primeros dos clientes de forma completamente gratuita.</p>
              </div>


            </div>

            <div className='display-label-none  mt-5 w-100' style={{ backgroundColor: "#fbfbfb" }}>

              <Form onSubmit={handleSubmit(handleSub,onError)} style={{ color: 'black' }}>

                {/* {
                        nombre !== "" && (
                            <p>Cambio nombre</p>
                        )
                    }
                     */}

                <div className='row g-0 mb-3 pt-3'>

                  <div className='col-sm-6'>
                    <div className="form-group">
                      <label className={"estilo_label"} htmlFor="nombre">Nombre </label>
                      <input type="text" className={"form-control border-0 border-bottom estilo_input"} id="nombre" title='Nombre de Abogado'

                        {...register('nombre', { required: "Nombre campo requerido" })}
                      />

                    </div>
                  </div>

                  <div className='col-sm-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label derecha"} htmlFor="correo">Correo Electrónico</label>
                      <input type="email" className={"form-control  border-0 border-bottom border-start estilo_input derecha"} id="correo" title='Correo de Abogado'
                        {...register('correo', { required: "Correo , campo requerido" })}
                      />

                    </div>
                  </div>

                </div>

                <div className='row mb-3 pt-3'>
                  <div className='col-6 errtype'>
                    {errors.nombre && (<p >{errors.nombre.message}</p>)}
                  </div>
                  <div className='col-6 errtype'>
                    {errors.correo && (<p >{errors.correo.message}</p>)}
                  </div>

                </div>

 


                <div className='row g-0 mb-3 pt-3'> {/* Esta es la carga de la foto */}

                  <div className='col-3 text-center' style={{ alignContent: 'center' }} >


                    <p className={"estilo_label"}>Fotografía</p>



                  </div>

                  <div className='col-3'>


                  {
                    theLoadPhoto && (
                      <div style={{marginTop:'25%' , width:'100%'}}>
                          <LoadPhoto showNormal={(val)=>setTheLoadPhoto(val)} setSelectedImage={(val)=>setImage(val)}></LoadPhoto>
                      </div>

                      
                    )
                  }

                  </div>

                  <div className='col-6 mt-2 d-flex flex-column align-items-center'>

                    <div style={{
                      
                      width: '40%', height: '40vh',
                      borderStyle: 'solid', borderWidth: '2px' , cursor:'grab'
                    }} onClick={()=>setTheLoadPhotoPrev(true)}>

                      {
                        image !== null && (                      
                        <img
                        alt="not found"
                        
                        width={"100%"} height={"100%"} style={{display:'block', margin:'auto'}}

                        resizeMode='contain'
                        
                        src={URL.createObjectURL(image)}
                      />


                        )
                      }


                    </div>

                  </div>

                </div>

                

                




                <div className='row g-0 mb-3 pt-5'>

                  <div className='col-sm-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label"} htmlFor="telefono">Teléfono </label>
                      <input type="text" className={"form-control  border-0 border-bottom estilo_input "} id="telefono" title='Teléfono de Abogado'
                        {...register('telefono', { required: false })}
                      />

                    </div>
                  </div>

                  <div className='col-sm-6' style={{ alignContent: 'center' }}>
                    {/* <div className="form-group"   >
                                <label htmlFor="password">Contraseña</label>
                                <input type="password" className="form-control" id="correo"  title='Contraseñs'
                                placeholder="Contraseña" style={{padding:'5px'}}
                                onChange={(n) => setPassword(n)} />
                                
                            </div> */}
                    {/* <div className="form-group">
                                <label htmlFor="password">Contraseña </label>
                                <input className='w-75 form-control' id='password'
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter your password"
                                />
                                <button onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>                         */}

                    <div className='pt-0'>
                      <PasswordInput register={register} />
                    </div>


                  </div>

                </div>

                <div className='row mb-3 pt-3'>
                  <div className='col-6 errtype'>
                    {errors.telefono && (<p >{errors.telefono.message}</p>)}
                  </div>
                  <div className='col-6 errtype'>
                    {errors.password && (<p >{errors.password.message}</p>)}
                  </div>

                </div>

                <div className='row g-0 mt-4 mb-3'>

                  <div className='col-12'>


                    <div className="form-group"   >
                      <label className={"estilo_label mb-3"} hhtmlFor="descripcion">Descripción Profesional </label>
                      <textarea className="form-control" id="descripcion" title='Descripción Profesional'

                        // Cómo centrar este textarea
                        style={{
                          backgroundColor: '#F8F8F8',
                          borderRadius: '0px', width: "100%", margin: "auto"
                        }}

                        {...register('descripcion', { required: "Descripción profesional , campo requerido" })}


                      />
                    </div>

                  </div>

                </div>

                <div className='row g-0 mb-3 pt-5'>

                  <div className='col-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label"} htmlFor="despacho">Despacho</label>
                      <input type="text" className={"form-control  border-0 border-bottom estilo_input"} id="despacho" title='Despacho'
                        onChange={(n) => setDespacho(n)} />

                    </div>

                  </div>

                  <div className='col-6'>

                    <div className="form-group"   >
                      <label className={"estilo_label derecha"} htmlFor="posicion">Posición</label>
                      <select type="text" placeholder='' className={"form-control  border-0 border-bottom border-start estilo_input derecha"} id="posicion" title='Posicion'>
                        <option></option>
                        {posicionArr.map(v => <option key={
                          v.id_position
                        } value={
                          v.id_position
                        }>

                          {v.position}
                        </option>
                        )}
                      </select>

                    </div>


                  </div>


                </div>


                <div className='row g-0 mb-3 pt-5'>

                  <div className='col-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label"} htmlFor="ubicacion">Ubicación</label>
                      <input type="text" className={"form-control  border-0 border-bottom estilo_input "} id="ubicacion" title='Ubicacion'
                        onChange={(n) => setUbicacion(n)} />

                    </div>

                  </div>

                  <div className='col-6'>

                    <div className="form-group"   >
                      <label className={"estilo_label derecha"} htmlFor="matricula">Matrícula</label>
                      <input type="text" className={"form-control  border-0 border-bottom border-start estilo_input derecha"} id="matricula" title='matricula'
                        onChange={(n) => setMatricula(n)} />

                    </div>


                  </div>


                </div>

                <div style={{marginTop:50}}>
                  <Fechas control={control} errors={errors}></Fechas>
                </div>
                

                <div className='mt-4'>

                  <p className={"subtitulos"}>Educación</p>

                </div>

                <Carrera title={'Licenciatura'} prefix={'lic'}
                  control={control} name2={'Prueba1'} errors={errors} isRequired={true} />
                <Carrera title={'Maestria'} prefix={'mae'}
                  control={control} name2={'Prueba2'} errors={errors} isRequired={true} />

                <Carrera title={'Doctorado'} prefix={'doc'}
                  control={control} name2={'Prueba3'} errors={errors} isRequired={true} />

                <Carrera title={'Diplomado'} prefix={'dip'}
                  control={control} name2={'Prueba4'} errors={errors} isRequired={true} />


                <Languages setLanguages={(val)=>setLanguages(val)}></Languages>


                <Servicios />
                <Especialidades />
                <Estados onFinalizar={handleSubmit(handleSub)} />



              </Form>

            </div>

          </div>



        </div>
        <Footer oscuro={true}></Footer>

      </div>
    </>
  )
}
