import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons/lib';
import SubMenu from './SubMenu';


// Los estilos se escriben en linea
const Nav = styled.div`
  /*background: #15171c;*/
  height: 30px;
  display: flex;
  justify-content: flex-end; /* flex-end */
  align-items: center;
  margin-top: 20px ;
  
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 25px;
  display: flex;
  justify-content: flex-end ; /* flex-start; */ /* flex-end */
  align-items: center;
  
`;

const SidebarNav = styled.nav`
  background: lightgray; /* #15171c */
  width: 25%;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top : 5px ;
  position: fixed;
  top: 100px;
  /*right : 1px ;*/
  right: ${({ sidebar }) => (sidebar ? '0%' : '25%')};

  /*width: ${({ sidebar }) => (sidebar ? '25%' : '0%')};*/

  transition: width 1500ms;  /* no estoy usando la transicion */
  z-index: 200;
`;

const SidebarWrap = styled.div`
  width: 100%;


  
`;

const Sidebar = ({select}) => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () =>{

    setSidebar(!sidebar);
  } 

  function setClose()
  {
    console.log( "Set Close") ;

    showSidebar() ;

  }

  console.log( "Rendering") ;

  // #fff

  function onIconSelect(value)
  {
      console.log( "onIconSelect " + value ) 
      showSidebar() 
      select(value) 
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} color='black' />
          </NavIcon>
        </Nav>

        { sidebar === true && (



        <SidebarNav sidebar={sidebar ? true : false}>
          <SidebarWrap>
            <NavIcon to='#'>
              <AiIcons.AiOutlineClose onClick={setClose} color='white' />
            </NavIcon>
            {SidebarData.map((item, index) => {
              //return <SubMenu item={item} key={index} />;
              return <SubMenu item={item} key={index}   iconselect={ onIconSelect}></SubMenu>
            })}
          </SidebarWrap>
        </SidebarNav>        
        )

        }
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;