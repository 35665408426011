//import logo from './logo.svg';
import React , { useEffect, useState } from 'react'



import Header from './components/Header/Header';

import { BrowserRouter , Route , Routes  } from 'react-router-dom'
import MiddleHeader from './components/Header/MiddleHeader';
import UsersView from './Rutas/Users/UsersView';



import AddLawer2 from './components/Lawers/AddLawer2';

import AddClient2 from './components/Client/AddClient2';




const customStyles = {
  content: {
    top: '55%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-40%',
    transform: 'translate(-50%, -50%)',
    backgroundColor : 'lightgray' ,
    borderRadius : '10px' ,
    width : '1000px'
  },
};


function App() {

  const [showMiddle,setShowMiddle] = useState( true ) ;
  const [theLogin,setTheLogin] = useState( false )
  const [showSidebar,setShowSidebar] = useState( true ) ;

  const [modalMessage,setModalMessage] = useState( "" ) ; // Este es el mensaje
  const [modalTitle,setModalTitle] = useState( "") // Este es el titulo
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [showAbogados,setShowAbogados] = useState( false)

  let subtitle;


  //let navigate = useNavigate() ;

  useEffect(()=> {

 


    window.onpopstate = e => 
    {
        setShowMiddle( true )
        setShowAbogados( false )
    }

  },[])




  function closeModal()
  {

    setIsOpen( false )
  }

  function afterOpenModal()
  {
    subtitle.style.color = '#f00';
  }


  function showTheLogin(value)
  {
    if ( value === "/session")
    {
      setTheLogin( !theLogin )
      return ;
    }

    if ( value === "/abogados")
    {
      //alert( "Soy abogado") ;

      setShowAbogados(true)

      setModalTitle( "Alta abogado")
      setIsOpen( true ) ;

      //navigate( "/abogados" )
    }

    if ( value === "/clientes")
      {
        //alert( "Soy abogado") ;
  
        setShowAbogados(true) // Y clientes
  
        setModalTitle( "Alta abogado")
        setIsOpen( true ) ;
  
        //navigate( "/abogados" )
      }

      if ( value === "/")
        {
          setShowAbogados(false) // Y clientes
  
        }
    
  }

  return (
    <div className="App" style={{backgroundColor:'white'}}>
      <BrowserRouter>
        <Header select={showTheLogin} showSide={showSidebar} ></Header>
 
        



        <Routes>

          <Route path="/" loader={() => console.log( "Cargando middleHeader")} element={

              <>
              {/* <Header select={showTheLogin} showSide={showSidebar} /> */}

              { ( showMiddle && !showAbogados) && (
                  <MiddleHeader showLogin={theLogin} setShowSide={(val) => setShowSidebar( val )}></MiddleHeader>
                  )
                }  


              
              </>
          }>

          </Route>


            <Route path="/users" Link="./Rutas/Users/UsersView" 
            element={<UsersView setShowMiddle={setShowMiddle}></UsersView> } />


            <Route path="/abogados"  
              element={<AddLawer2></AddLawer2> } />

            <Route path="/clientes"  
              element={<AddClient2></AddClient2> } />


          

          {/* <Route path="/besview" Link="./components/BesCustomer/BesView" element={<BesViewPrev></BesViewPrev>} /> */}
  
      </Routes>
      </BrowserRouter>
      {/*{
        !showAbogados && false  && (
          <>
          
            <AddLawer2/>
            <AddClient2></AddClient2>
          </>
        ) 
      } */}    
    </div>
  );
}

export default App;
