
import React, { useState, useRef, useEffect } from 'react'
import "../../styles/AddLawer2Styles.css"
import mapa from "../../images/MapaRepublica.jpg"
import axios from "axios";

import GetUrl from "../../ApiCall/GetUrl";


export default function Estados({ onFinalizar }) {
  const [statesArr, setStatesArr] = useState([]);
  useEffect(() => {
    axios.get(GetUrl() + "get_states").then(response => {
      setStatesArr(response.data);
    }).catch(err => {
      setStatesArr(["Hubo un error al traer la información"])
    })

  }, [])
  return (
    <div className='row g-0 mt-4'>

      <div className='col-sm-6'>
        <div className='border-0'
          style={
            {
              height: '150px',
              marginTop: "150px"
            }
          }>
          <p className={'mt-4 ms-4'}
            style={
              {
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: "left"
              }
            }>Estado de la República</p>
          <p style={
            {
              fontSize: '18px',
              fontWeight: 'lighter',
              textAlign: "left",
              paddingLeft: "25px"
            }
          }>Escoge los Estados en los que puedes brindar tus servicios legales</p>
        </div>
      </div>
      <div className='col-sm-6 d-flex flex-column align-items-center'>
        <select placeholder="" className='mt-5 ms-4 w-75 estilo_select' name="estado" id="estado">
          <option></option>
          {statesArr.map(v => 
            <option key={v.id_state} value={v.id_state}>{v.name}</option>
          )}
        </select>
        <div className={"estilo_cuadro"}>
          <img src={mapa} width={"400px"} height={"305px"}></img>

        </div>
      </div>

      <div className='row align-items-center'>
        <div className='col-6'>
          <div className="form-group">
            <label className={"estilo_label"}
              htmlFor="rfc">RFC</label>
            <input type="text"
              className={"form-control  border-0 border-bottom border-end estilo_input"}
              id="rfc"
              title='Rfc' />

          </div>
        </div>

        <div className='col-6 text-center'>

          <div className='form-group w-100 ' style={{marginTop:'38px'}}>
            <button type='submit' className='btn btn-primary btn-block rounded-0'
              style={
                {
                  width: '100%',
                  position: 'relative',
                  background: '#fBfBfB',
                  color: '#0171E3',
                  border: '1px solid #',
                  padding: '8px 16px',
                  transition: 'all 0.3s ease',
                  overflow: 'hidden',
                  marginTop: "35px"
                }
              }
              onMouseOver={
                (e) => {
                  e.target.style.border = '1px solid #c0c0c0';
                  e.target.style.color = '#c0c0c0';
                }
              }
              onMouseOut={
                (e) => {
                  e.target.style.border = '1px solid #007bff';
                  e.target.style.color = '#007bff';
                }
              }>Finalizar</button>
          </div >
        </div>
      </div>


    </div>

  )
}
