import React , { useEffect , useState }from 'react'


import Sidebar from '../Sidebar/Sidebar'


export default function Header({select , showSide}) {
    const [pleft,setPleft] = useState( '40px')
    const [showSidebar,setShowSidebar] = useState( true ) ;

    useEffect( () => {

        console.log( "Header setShowSideBar = " + showSide ) ;

        setShowSidebar( showSide ) ;
    } , [showSide])

    useEffect(() => {
        const handleScroll = (event) => {
          //setScrollTop(window.scrollY);
          console.log( "scroll " + window.scrollY)
          console.log( "innerWidth " + window.innerWidth)
          console.log( "Outer " + window.outerHeight )
          calcPleft( window.scrollY )
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      //const pleft = '400px'

      function calcPleft(  )
      {
        var scroll =  window.scrollY
        var innerWWidth = window.innerWidth
        var outerHeigth = window.outerHeight 

        if ( scroll < outerHeigth )
        {
            var rel = scroll / outerHeigth ;

            console.log( "rel = " + rel ) ;

            var desp = ( ( innerWWidth  ) /2 ) * rel 

            if ( desp > ( ( innerWWidth - 200 ) / 2 ))
            {
                return  
            }

            if ( desp < 40  )
            {
                return ;
            }


            console.log( "desp = " + Math.trunc( desp ))



            setPleft( Math.trunc (desp).toString() + "px" )

        }
        else{

            console.log( "desp(2) = " + Math.trunc (( innerWWidth /2)).toString() )

            if ( desp > ( ( innerWWidth - 200 ) / 2 ))
            {
                return ;
            }


            setPleft( Math.trunc (( innerWWidth - 200)/2).toString() + "px"  ) 
        }
      }

      function onSelect(value)
      {
        select(value)
      }

  return (



    <div style={{paddingBottom : '50px'}}> 

        <header  style={{backgroundColor: 'white' , height: '70px' , 
            width:'100%' , position :'fixed'  , zIndex:100}}>

            <div className='row pt-2'>
                <div className='col-4 text-start' style={{color:'black' }}>

                    {/* <Sidebar></Sidebar> */}

                    <div>
                        <p className='pt-1' style={{fontSize:'35px' , paddingLeft : "40px" , fontFamily:'Satoshi-Regular' }}>LegalTek</p>
                    </div>

                    

                    

                </div>

                {/* <div className='col-2 align-content-end ' style={{color:'white'}}>
                     
                    
                </div> */}

                <div className='col-4'>

                  
                    {/* <img className='pt-0 mt-0' style={{ borderRadius: '0%'}} height={45} src={Logo} alt=''></img>  */}
                </div>


                <div className='col-2'>
                    {/* <img height={40} src='https://snapon.com.mx/wp-content/uploads/2020/12/logo-snap-on-2x-1.png' alt=''></img> */}
                    
                </div>


                {
                    showSidebar && (
                        <div className='col-2 pe-5' id="sidebar" style={{zIndex:100 }}>

                            <Sidebar select={onSelect}></Sidebar>

                        </div>

                    )


                }


            </div>

        

        </header>
    </div>
  )
}
