import React, { useState } from 'react';
import { icons } from 'react-icons/lib';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';


import { Navigate } from 'react-router-dom'

const SidebarLink = styled(Link)`
  display: flex;
  /*color: #e1e9fc; */ /* este es el color del font */
  color : black ;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #252831;
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #414757;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    background: #632ce4;
    cursor: pointer;
  }
`;

const SubMenu = ({ item , iconselect}) => {
  const [subnav, setSubnav] = useState(false);

  const navigate = useNavigate()

  const showSubnav = () => { setSubnav(!subnav) ; console.log( "Tecleando subnav") };



  function onFound( value )
  {

    console.log( "Hemos entontrado a " + value ) 


    if ( value === "/abogados")
    {
      console.log( "Nos vamos a /abogados")
      navigate("/abogados")
    }

  
    if ( value === "/clietes")
      {
        console.log( "Nos vamos a /clientes")
        navigate("/clientes")
      }
  



    iconselect( value ) ;

  }

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel onClick={() => onFound( item.path )}  >{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} >
              <div >
                {item.icon} 
                <SidebarLabel >{item.title}</SidebarLabel>
              </div>

            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;