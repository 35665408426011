import React , {useEffect , useState} from 'react'

import { motion } from "framer-motion"
import UpImageHeader from './UpImageHeader'
import TextQueremos from './TextQueremos'
import CardRow from './CardRow'
import Footer from '../Footer/Footer'
import Login from '../Login/Login'
import NewUser from '../Login/NewUser/NewUser'
import Carrusel from "./Carrusel"
import CuadroCentral from './CuadroCentral'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "../Client/FadeIn.css"
import { useInView } from "react-intersection-observer";
import FadeInElementTopDown from './FadeInElementTopDown'

//justify-content-end
// style={{  top:'0px' , left :'0px' , zIndex:100 }}
export default function MiddleHeader({showLogin , setShowSide }) {

    const sloganStyle = {
        fontWeight: "bold", paddingLeft: "30px", fontSize: "55px", margin: "3%", paddingTop: "300px", paddingBottom: "300px", fontWeight: "900"
    }

    // Fade's in animations

    // Hook para hacer el efecto fadein de las primeras tres líneas.
    const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 1 });
    const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 1 });
    const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 1 });

    // Hook para "La Justicia jamás había sido tan fácil"
    const [ref4, inView4] = useInView({triggerOnce: true, threshold: 0.5});

    // Hook para "Quiénes somos"
    const [ref5, inView5] = useInView({triggerOnce: true, threshold: 1});

    // Hook para "Abogados. Documentos....."
    const [ref6, inView6] = useInView({triggerOnce: true, threshold: 0.1})

    useEffect(() => {
        if (inView1) {
          setTimeout(() => {
            document.getElementById('line1').classList.add('visible');
          }, 0);
          setTimeout(() => {
            document.getElementById('line2').classList.add('visible', 'delay-1');
          }, 500);
          setTimeout(() => {
            document.getElementById('line3').classList.add('visible', 'delay-2');
          }, 1000);
        }
      }, [inView1]);

    // Parte del carrusel
    const items = [
       <Carrusel titulo={"Encuentra a un Abogado"} texto={"AAAAAAAAAAAAAAAAAAAAAAAA"}></Carrusel>,
       <Carrusel titulo={"Consulta Express"} texto={"AAAAAAAAAAAAAAAAAAAAAAAA"}></Carrusel>,
       <Carrusel titulo={"Obtén un documento"} texto={"AAAAAAAAAAAAAAAAAAAAAAAA"}></Carrusel>, 
       <Carrusel titulo={"Asesoría Migratoria"} texto={"AAAAAAAAAAAAAAAAAAAAAAAA"}></Carrusel>, 
]

    const [theLogin,setTheLogin] = useState( false ) ;
    const [theNewUser,setTheNewUser] = useState( false ) ;


    function handleAddMember()
    {
        setTheLogin( false ) ;
        setTheNewUser( true ) ;
    }


    function onSalir(val)
    {

        console.log( "Se solicita salir = " + true) ;


        if ( !val )
        {
            setShowSide( false ) ;
            setTheLogin( true ) ;
            return ;
        }

        setTheLogin( false )
        
        setShowSide( true ) ;

    
        
    }

    useEffect(()=>{

        console.log( "Vamos a cambiar la opacidad " + showLogin )

        if ( showLogin )
        {
            setTheLogin( true  )
        }
        else{
            setTheLogin( false )

        }

    } , [showLogin])

  return (
    <div  id="theMiddle"  className='pt-0 pe-0 w-100' style={{ paddingTop : '150px' , fontFamily : 'Satoshi-Regular' }}>


        <div >
            <div className='d-flex justify-content-end mb-0 pt-1' style={{fontSize:'25px',
                fontWeight:'bold' , height:'24px' ,zIndex:101 , backgroundColor :'white' }}>

                <div style={{ position:'absolute' , top:'50px' , right :'0px' }}>                                 
                    <div className="pe-4">
                        <div ref={ref1} id="line1" className="d-flex justify-content-end interlineado pe-2 fade-in" style={{ fontSize: '40px', fontWeight: 'bold', height: '22px', color: '#FFFFFF', padding: '20px', marginTop: '100px' }}>
                            <p>Los Mejores Abogados</p>
                        </div>
                        <div ref={ref2} id="line2" className="d-flex justify-content-end interlineado pe-2 fade-in" style={{ fontSize: '40px', fontWeight: 'bold', height: '22px', marginTop: '60px', color: '#FFFFFF', padding: '20px' }}>
                            <p>De Cualquier Especialidad</p>
                        </div>
                        <div ref={ref3} id="line3" className="d-flex justify-content-end pe-2 fade-in" style={{ fontSize: '40px', fontWeight: 'bold', height: '22px', color: '#FFFFFF', padding: '20px', marginTop: '60px' }}>
                            <p>De Cualquier parte de México.</p>
                        </div>
                    </div>                
                </div>


            </div>
            {/* <hr className='mt-0 mt-3 mb-0' ></hr> */}

        </div>

        <div 
            className='d-flex justify-content-center pt-0 ' style={{zIndex:1}} >
            
            <UpImageHeader></UpImageHeader>

        </div>
        <div  className={`blend-text`} style={{position: "relative"}}>
            <h1 ref={ref5} className={`d-flex justify-content-left fade-in ${inView5 ? 'visible': ''}` }style={{color: "black", 
                fontWeight: "bold", 
                fontSize: "90px", 
                paddingLeft: "20px", 
                zIndex: 2, 
                transform: "translateY(-40px)", 
                fontWeight: "900"}}>¿Quiénes Somos?</h1>

        </div>
            {/*Texto de quienes somos */}
            <div>
                <h1 style={{
                    fontSize: "48px",
                    margin: "300px"
                }}>AAAAAAAAAAAAAAAAAAAAAA</h1>

            </div>


        
        {/*Carrusel */}
        <div className='justify-content-center'>
            <AliceCarousel mouseTracking items={items} infinite={true} keyboardNavigation={true} animationDuration={500} disableButtonsControls={false}/>
        </div>

        {/*Frase */}
        <div ref={ref6} className={`d-flex justify-content-left fade-in ${inView6 ? 'visible': ''}`}>
            <h1 style={{ fontWeight: "900", paddingLeft: "30px", fontSize: "47px", margin: "3%", marginBottom: "5%"}}> Abogados.  Documentos.  Lo  que  necesites...</h1>
        </div>
        {/*Cuadro negro */}
        <div className='d-flex justify-content-center' style={{width: "100%"}}>
            <CuadroCentral></CuadroCentral>
        </div>
        {/* TODO revisar el fade-in aquí */}
        <div className={`d-flex justify-content-center`}>
      <h1
        ref={ref4}
        className={`fade-in ${inView4 ? 'visible' : ''}`}
        style={sloganStyle}
      >
        <b>La justicia jamás había sido tan fácil.</b>
      </h1>

        </div>

        <Footer></Footer>

        {
            theLogin === true && (
                <Login salir={(val) => onSalir(val)} addMember={handleAddMember} 
                    setShowSide={() => console.log( "setShowSide")}></Login>
            )

        }

        {
            theNewUser === true && (
                <NewUser salir={(val) => onSalir(val)} addMember={handleAddMember} 
                    setShowSide={() => console.log( "setShowSide")}></NewUser>
            )

        }
        

        
    </div>
  )
}
