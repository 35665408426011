// src/PasswordInput.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import "../../styles/AddLawer2Styles.css"


const InputWrapper = styled.div`
  position: relative;
  
`;

const StyledInput = styled.input`
  padding-right: 30px;
  
`;

const ShowPasswordButton = styled.button`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
`;

export default function PasswordInput  ( {register})  {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputWrapper>
      <label className={"estilo_label derecha"} htmlFor='password'>Contraseña</label>
      <StyledInput id='password'
        type={showPassword ? 'text' : 'password'} className={"form-control  border-0 border-bottom border-start estilo_input derecha"}
          {...register('password', { required : "Password , campo requerido" })}
        
      />
      <ShowPasswordButton onClick={togglePasswordVisibility}>
      
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </ShowPasswordButton>
    </InputWrapper>
  );
};

//export default PasswordInput;