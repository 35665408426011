import React , {useEffect , useState }from 'react'

//import AddLawyerDate from './AddLawyerDate'
//import AddLawyerDate from './AddLawyerDate'
import FechaNac from './FechaNac'
import "../../styles/AddLawer2Styles.css"

export default function Fechas({control,errors }) {


  return (
    <div className='row g-0'>

        <div className='col-6'>

            <div style={{textAlign:'start'}}>
                <p style={{fontSize:'25px'}}>Fecha de Inicio del Ejecicio Profesional</p>
            </div>
            

            <div className="form-group mb-1" >
            
                <FechaNac  control={control} errors={errors} name={"Fecha de Inicio del Ejecicio Profesional"} isRequired={true}  />
                
            </div>



        </div>

        <div className='col-6'>            

            <div style={{textAlign:'left'}}>
                <p style={{fontSize:'25px'}}>&nbsp; &nbsp; Fecha de Nacimiento</p>
            </div>
            
            <div className="form-group mb-1" >
                <FechaNac  control={control} errors={errors} name={"Fecha de Nacimiento"} isRequired={false}  />
                
            </div>

        </div>

    </div>
  )
}
