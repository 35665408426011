
import React , {useEffect , useState }from 'react'

//import AddLawyerDate from './AddLawyerDate'
//import AddLawyerDate from './AddLawyerDate'
import FechaNac from './FechaNac'
import "../../styles/AddLawer2Styles.css"
import { pickersFadeTransitionGroupClasses } from '@mui/x-date-pickers'

export default function Carrera( { title , prefix , control , errors , name2 , isRequired}) { // Tag lic , mae , uni
    
    //const [titletag,setTitleTag] = useState( '')
    const [name,setname] = useState( "")
    const [univ,setUniv] = useState( "")
    const [univfecha,setUnivFecha] = useState( "")

    useEffect(() => {
        setname( prefix )
        setUniv( prefix  +  'univ'  ) // maestriauniv
        setUnivFecha( prefix + "fecha") // mestria



    } , [])
  return (

    
    <div className='row g-0'>

        <div className='col-4'>
            <div className="form-group"   >
                {/* <label htmlFor={name}>{title}</label> */}
                <input type="text" className={"form-control  border-0 border-bottom estilo_input "} id={name}  title={title}
                placeholder={title} />
                
            </div>

        </div>

        <div className='col-4'>

            <div className="form-group"   >
                <input type="text" className={"form-control  border-0 border-bottom border-start estilo_input"} id={univ}  title='Universidad'
                placeholder='Universidad'
                />
                
            </div>

        </div>


        <div className='col-4'>

            <div className="form-group mt-0" >
                <FechaNac  control={control} errors={errors} name={name2} isRequired={false}  />
                {/*<label style={{fontStyle:'italic' , fontWeight : '100'}}  htmlFor={univfecha}>Fecha</label>
                <input type="text" className="form-control  border-0 border-bottom border-start" id={univfecha}  title='Fecha'
                style={{padding:'5px' ,  backgroundColor : '#F8F8F8' ,borderRadius : '0px' }}
                /> */}
                
            </div>

        </div>

    </div>



  )
}
