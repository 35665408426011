import React from 'react'

import Image from '../../images/Oleaje.jpg'

export default function UpImageHeader() {
  return (
    <div >
        <img  src={Image} width='100%' height='103%' alt=''></img>
    </div>
  )
}
