import './Footer.css'

//import logohh from './Images/logo-hunterandhire-white.svg'
import logoinsta from './Images/logo-instagram.svg'
import logowhats from './Images/logo-whatsapp-white.svg'
import logoface from './Images/logo-facebook.svg'

function Footer({ oscuro = false }) {

  const textStyle = {
    color: oscuro ? "#FFFFFF" : "#000000"
  }

  const backgroundStyle = {
    backgroundColor: oscuro ? "#000000" : "#FFFFFF"
  }

  return (
    <div className='footer d-flex align-content-center' style={{ ...backgroundStyle, width: '100%', height: "590px" }}>

      <div className=' p-0 m-0  w-100'>

        <div className='d-flex ps-4 pt-3'>
          <div className='row hypr'>
            <div className='col_aux links'>
              <a href='#' style={{ ...textStyle }}>Abogados</a> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a href='#' style={{ ...textStyle }}>Consultas</a>  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a href='#' style={{ ...textStyle }}>Documentos</a>  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a href='#' style={{ ...textStyle }}>Empresas</a>  &nbsp; &nbsp;&nbsp;

            </div>
            <div className='col_aux m-auto name'> LegalTek</div>
            <div className='col_aux'></div>
          </div>
        </div >


        <div className='row' style={{ paddingTop: '50px' }}>

          <div className='col-12' style={{ color: 'white' }}>
            {/* <p style={{fontSize:'90px' , fontFamily :'Satoshi-Bold'}}>L&nbsp;&nbsp;e&nbsp;&nbsp;g&nbsp;&nbsp;a&nbsp;&nbsp;l&nbsp;&nbsp;T&nbsp;e&nbsp;k</p> */}
            <p style={{ ...textStyle, fontSize: '100px', fontFamily: 'Satoshi-Bold', padding: "40px" }}>Cambia Paradigmas</p>
          </div>

        </div>


        <div className='row text-center' style={{ marginTop: "50px" }}>


          <a href='#' style={{ ...textStyle, marginBottom: "80px" }}>¿Quiénes somos?</a>


        </div>



        <div className='row'>
          <div >
            <div style={{ margin: '30px -20px 20px' }}>
              <hr />
            </div>
          </div>

        </div>


        <div className='row'>

          <div className='col-9 text-start'>

            <p className='ps-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

          </div>

          <div className='col-3 pe-5 text-end'>
            <a href='#'>Aviso de Privacidad</a>
          </div>


        </div>



      </div>



    </div>
  )
}

export default Footer;
