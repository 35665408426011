import React from 'react';

export default function Carrusel({titulo, foto, texto}) {
    const containerStyle = {
        display: 'flex',
        width: '95%',
        height: '860px',
        position: 'relative',
    };

    const leftBoxStyle = {
        flex: 2,
        backgroundColor: '#FBFBFB',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
    };

    const rightBoxStyle = {
        flex: 1, 
        backgroundColor: 'lightblue',
        padding: '10px',
    };

    const buttonStyle = {
        backgroundColor: "#0171E3",
        fontSize: "20px",
        color: "#FFFFFF",
        borderRadius: "20px",
        width: "183px",
        height: "54px",
        boxShadow: "none",
        border: "none",
        position: 'absolute',
        bottom: '20px', 
        left: '50%',
        transform: 'translateX(-50%)',
    };

    return (
        <div style={containerStyle}>
            <div style={leftBoxStyle}>
                <h1 className="justify-content-left" style={{ fontSize: "73px", fontWeight: "700", marginLeft: "10px" }}>
                    <b>{titulo}</b>
                </h1>
                <div>
                    <p style={{ fontSize: "43px" }}>
                        {texto}
                    </p>
                </div>
            </div>
            <div style={rightBoxStyle}>
                {/* Aquí puedes agregar contenido al lado derecho */}
            </div>
            <button style={buttonStyle}>Buscar</button>
        </div>
    );
}
