import React , {useState, useEffect} from 'react'

import logoinsta from '../Footer/Images/logo-instagram.svg'
import logoface from '../Footer/Images/logo-facebook.svg'

import Linkin from '../Footer/Images/linkin.png'



import { FaInstagramSquare } from 'react-icons/fa'

//import { } from 'react-icons/io'

import { IoLogoLinkedin } from 'react-icons/io'

import { FaLinkedinIn } from "react-icons/fa";






import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Email from './Email'
import { RiFontSize } from 'react-icons/ri'



function Login({salir , addMember }) {

    const [errorMessage,setErrorMessage] = useState("") ;
    const [isReenter,setIsReenter] = useState( false ) ;
    const [password,setPassword] = useState( "" ) ;


    function onAddMember()
    {
        console.log( "onAddMember") ;
        addMember() ;
    }

    useEffect(() => {

        console.log( "useEffect setShowSide(2)") ;

        salir( false ) ;

    } ,[])

    function handleSubmit()
    {

    }


    function passwordChange(e)
    {
        var pass = document.getElementById( "password").value ;

        if ( pass.length > 0 ) {
            setIsReenter( true )
        }
        else{
            setIsReenter( false )
        }

    }

  return (
    <div className='box-login float-end' style={{ top:'110px' , 
        right:'20px' , backgroundColor :'lightgray' , position : 'fixed' , zIndex : 200}} >

    <div className='d-flex flex-column align-items-center' 
        style={{borderWidth:2 , borderColor :'black' , fontFamily : 'Satoshi-Bold'}}>

        <div>
            <p className='tx-color-purple body-1'>Login</p>
        </div>

        <div className='display-label-none w-100'>       


            <form id='myForm form-2' onSubmit={handleSubmit} 
                style={{color:'black' , textAlign :'left' , fontSize :'12px'}}>

                {/* <Email email={'samuel_etelco@hotmail.con'}></Email> */}
        
                <div className=' m-4 mb-2'  >

                    {/* <div className="form-group m-4"> */}
                        <label htmlFor='email ' >Correo</label>
                        <input type="email" id="email" className="form-control" placeholder="Correo"/>
                        
                    {/* </div> */}
                </div>
                
                <div className="form-outline m-4 mb-2 mt-2"  >
                    <label  htmlFor="password">Contraseña</label>
                    <input type="password" id="password" onChange={passwordChange} 
                        className="form-control" placeholder="Contraseña"/>
                    
                </div>


                <div class="row ms-3 m-4 mb-2 mt-2">
                    <div class="form-group col-6  ">
                    <label  htmlFor="telefono">Teléfono</label>
                    <input type="tel" id="telefono" onChange={passwordChange} 
                        className="form-control" placeholder="Teléfono"/>                    </div>
                    <div class="form-group col-6">
                    <label  htmlFor="edad">Edad</label>
                    <input type="number" id="edad" onChange={passwordChange} 
                        className="form-control" placeholder="Edad"/>
                    </div>
                </div>           

                {/* <div className="form-outline m-4 mt-2">
                    <label  htmlFor="telefono">Teléfono</label>
                    <input type="tel" id="telefono" onChange={passwordChange} 
                        className="form-control" placeholder="Teléfono"/>
                    
                </div>          

                <div className="form-outline m-4 mt-2">
                    <label  htmlFor="edad">Edad</label>
                    <input type="number" id="edad" onChange={passwordChange} 
                        className="form-control" placeholder="Edad"/>
                    
                </div>          */}
  


                { isReenter && (
                                <div className="form-outline m-4" style={{color:'grey'}}>
                                    <label className="form-label"  htmlFor="respassword">Re entre Contraseña</label>
                                    <input type="password" id="repassword" className="form-control" placeholder="Re entre Contraseña"/>
                                    
                                </div>
                )

                } 

                <div className='row'>

                    <div className='col-1'>

                    </div>
                    <div className='col-4'>
                        <button type="button" onClick={handleSubmit} className="button-2 m-4">Registrar</button>
                    </div>
                    <div className='col-1'>

                    </div>
                    

                    <div className='col-4'>
                        <button type="button" onClick={()=>salir(true)} className="button-2 m-4">Salir</button>
                    </div>

                    <div className='col-2'>

                    </div>
                    
                </div>

                {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}

                <div className="d-flex m-4 gap-2">

                    <div>
                        <a className='tx-color-purple body-3' href="#!">Olvido contraseña?</a>
                    </div>
                </div>

                <div className="d-flex body-3 gap-1 m-4">

                    {/* <p onClick={onAddMember}>No eres miembro ? Registrate</p> */}
                    <p>No es miembro? <a href='#' onClick={onAddMember} className='tx-color-gray-dark'>Registrarse</a></p>
                    <p>o registrarse con:</p>
                    &nbsp;&nbsp;<a href='#'><img src={FaInstagramSquare} alt="logohh"/></a>
                    &nbsp;&nbsp;<a href='#'><img src={FaInstagramSquare} alt="logohh"/></a>
                    &nbsp;&nbsp;<a href='#'><img src={FaLinkedinIn} alt="logolinkin" /></a>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                    <i className="fab fa-google"></i>
                    </button>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                    <i className="fab fa-twitter"></i>
                    </button>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                    <i className="fab fa-github"></i>
                    </button> 
                </div>
            </form> 
        </div>
    </div>
</div>

  )
}

export default Login