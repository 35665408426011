import React , {useState, useEffect} from 'react'

//import logoinsta from '../Footer/Images/logo-instagram.svg'

import { IoLogoInstagram } from 'react-icons/io'

import { IoLogoFacebook } from 'react-icons/io'

import { IoLogoLinkedin } from 'react-icons/io'

//import logoface from '../Footer/Images/logo-facebook.svg'

//import Linkin from '../Footer/Images/linkin.png'



// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Email from './Email'
// import { RiFontSize } from 'react-icons/ri'



function NewUser({salir }) {

    const [errorMessage,setErrorMessage] = useState("") ;
    const [isReenter,setIsReenter] = useState( false ) ;
    const [password,setPassword] = useState( "" ) ;
    const [nombre,setNombre] = useState( "" ) ;

    function ocupacionChange()
    {

    }

    function telChange()
    {

    }

    function edadChange()
    {

    }

    function nombreChange(e)
    {

        var value = document.getElementById( "nombre").value

        console.log( "Name = " + value )

        setNombre( value ) ;
    }

    function onAddMember()
    {
        console.log( "onAddMember") ;
    }

    useEffect(() => {

        console.log( "useEffect setShowSide(2)") ;

        salir( false ) ;

    } ,[])

    function handleSubmit()
    {

    }


    function valpasswordChange()
    {

    }


    function passwordChange(e)
    {
        var pass = document.getElementById( "password2").value ;

        console.log( "pass = " + pass )

        if ( pass.length > 0 ) {
            setIsReenter( true )
        }
        else{
            setIsReenter( false )
        }

    }

  return (
    <div className='box-login float-end' style={{ top:'110px' , 
        right:'20px' , backgroundColor :'lightgray' , position : 'fixed' , zIndex : 200}} >

    <div className='d-flex flex-column align-items-center' 
        style={{borderWidth:2 , borderColor :'black' , fontFamily : 'Satoshi-Bold'}}>

        <div>
            <p className='tx-color-purple body-1'>Nuevo Usuario</p>
        </div>

        <div className='display-label-none w-100'>       


            <form id='myForm form-2' onSubmit={handleSubmit} 
                style={{color:'black' , textAlign :'left' , fontSize :'12px'}}>

                {/* <Email email={'samuel_etelco@hotmail.con'}></Email> */}


                <div className="form-outline m-4 mb-0 mt-2"  >
                    <label  htmlFor="nombre">Nombre</label>
                    <input type="text" id="nombre" onChange={nombreChange} 
                        className="form-control" placeholder="Nombre"/>
                    
                </div>
        
                <div className=' m-4 mb-2 mt-1'  >

                    {/* <div className="form-group m-4"> */}
                        <label htmlFor='email ' >Correo</label>
                        <input type="email" id="email" className="form-control" placeholder="Correo"/>
                        
                    {/* </div> */}
                </div>
                
                <div className="form-outline m-4 mb-2 mt-2"  >
                    <label  htmlFor="password2">Contraseña</label>
                    <input type="text" id="password2" onChange={passwordChange} 
                        className="form-control" placeholder="Contraseña"/>
                    
                </div>

                {
                    isReenter && (
                        <div className="form-outline m-4 mb-2 mt-2"  >
                            <label  htmlFor="validapassword">Valida Contraseña</label>
                            <input type="password" id="validapassword" onChange={valpasswordChange} 
                                className="form-control" placeholder="ValidaContraseña"/>
                        </div>
                    )
                }



                <div class="row  m-4 mb-2 mt-2">
                    <div class="form-group col-6  ">
                    <label  htmlFor="telefono">Teléfono</label>
                    <input type="tel" id="telefono" onChange={telChange} 
                        className="form-control" placeholder="Teléfono"/>                    </div>
                    <div class="form-group col-6">
                    <label  htmlFor="edad">Edad</label>
                    <input type="number" id="edad" onChange={edadChange} 
                        className="form-control" placeholder="Edad"/>
                    </div>
                </div>           


                <div class="  m-4 mb-2 mt-2">

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="masculino" id="exampleRadios1" value="option1" checked/>
                        <label className="form-check-label" htmlFor="exampleRadios1">
                            Masculino
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="masculino" id="exampleRadios2" value="option2"/>
                        <label className="form-check-label" htmlFor="exampleRadios2">
                            Femenino
                        </label>
                    </div>

                </div>


                <div className='m-4 mb-2 mt-3'>
                    <select class="form-select " aria-label="Default select example" 
                        onChange={ocupacionChange}>
                        <option selected>Seleccione Ocupación</option>
                        <option value="1">Mercadotenia</option>
                        <option value="2">Nogocios</option>
                        <option value="3">Servicios Legales</option>
                        <option value="4">Otro</option>
                    </select>
                </div>


                <div class="  m-4 mb-2 mt-2">

                    <div className="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="fisica" value="option1" checked/>
                        <label class="form-check-label" for="fisica">
                            Persona Física
                        </label>
                    </div>
                    <div className="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="moral" value="option2"/>
                        <label class="form-check-label" for="moral">
                            Persona Moral
                        </label>
                    </div>

                </div>


                {/* <div className="form-outline m-4 mt-2">
                    <label  htmlFor="telefono">Teléfono</label>
                    <input type="tel" id="telefono" onChange={passwordChange} 
                        className="form-control" placeholder="Teléfono"/>
                    
                </div>          

                <div className="form-outline m-4 mt-2">
                    <label  htmlFor="edad">Edad</label>
                    <input type="number" id="edad" onChange={passwordChange} 
                        className="form-control" placeholder="Edad"/>
                    
                </div>          */}
  



                <div className='row'>

                    <div className='col-1'>

                    </div>
                    <div className='col-4'>
                        <button type="button" onClick={handleSubmit} className="button-2 m-4">Registrar</button>
                    </div>
                    <div className='col-1'>

                    </div>
                    

                    <div className='col-4'>
                        <button type="button" onClick={()=>salir(true)} className="button-2 m-4">Salir</button>
                    </div>

                    <div className='col-2'>

                    </div>
                    
                </div>

                {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}
{/* 
                <div className="d-flex m-4 gap-2">

                    <div>
                        <a className='tx-color-purple body-3' href="#!">Olvido contraseña?</a>
                    </div>
                </div>

                <div className="d-flex body-3 gap-1 m-4">

                    
                    <p>No es miembro? <a href='#' onClick={onAddMember} className='tx-color-gray-dark'>Registrarse</a></p>
                    <p>o registrarse con:</p>
                    &nbsp;&nbsp;<a href='#'><img src={IoLogoFacebook} alt="logohh"/></a>
                    &nbsp;&nbsp;<a href='#'><img src={IoLogoInstagram} alt="logohh"/></a>
                    &nbsp;&nbsp;<a href='#'><img src={IoLogoLinkedin} alt="logolinkin" 
                        style={{width:'40px', backgroundColor:'gray'}}/></a>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                    <i className="fab fa-google"></i>
                    </button>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                    <i className="fab fa-twitter"></i>
                    </button>

                    <button type="button" className="btn btn-link btn-floating mx-1">
                    <i className="fab fa-github"></i>
                    </button> 
                </div> */}
            </form> 
        </div>
    </div>
</div>

  )
}

export default NewUser