import React from 'react';
import * as FaIcons from 'react-icons/fa';
//import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  // {
  //   title: 'Quienes somos',
  //   path: '/overview',
  //   icon: <AiIcons.AiFillHome />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [
  //     {
  //       title: 'Operaciones México',
  //       path: '/overview/users',
  //       icon: <IoIcons.IoIosPaper />
  //     },
  //     {
  //       title: 'Operaciones Internacionales',
  //       path: '/overview/revenue',
  //       icon: <IoIcons.IoIosPaper />
  //     }
  //   ]
  // },
  {
    title: 'Iniciar Sesión',
    path: '/session',
    icon: <FaIcons.FaUser />
  },
  
  {
    title: 'Encuentra un Abogado',
    path: '/abogado',
    icon: <IoIcons.IoIosSearch />,
    // iconClosed: <RiIcons.RiArrowDownSFill />,
    // iconOpened: <RiIcons.RiArrowUpSFill />,

  },


  {
    title: 'Consulta Express',
    path: '/express',
    icon: <FaIcons.FaSteam />
  },
  {
    title: 'Encuentra Documentos',
    path: '/documentos',
    icon: <IoIcons.IoMdBook />
  },
  {
    title: 'Soy Abogado',
    path: '/abogados',
    icon: <FaIcons.FaBalanceScaleLeft />
  },
  {
    title: 'Soy Cliente',
    path: '/clientes',
    icon: <FaIcons.FaPeriscope />
  },

  {
    title: 'Inicio',
    path: '/',
    icon: <FaIcons.FaHotel />
  },



];