import React , { useEffect , useState }from 'react'



import Select from 'react-select'

//import { ApiCallGet , ApiCallPost } from '../../ApiCall/ApiCall'


import GetUrl from '../../ApiCall/GetUrl'
import axios from 'axios'

export default function Languages( { setLanguages }) {

    const [options,setOptions] = useState( [])

function onChange( val )
{
    console.log( "Val = " , val )

    setLanguages( val )
}

useEffect(() => {



    axios( GetUrl() + "get_lawyer_languages" )
    .then((response) => {

        console.log( "Los datos son : =====>>>> " , response + " elementos = " + response.data.length )

        var arr = []

        for ( var i = 0 ; i < response.data.length ; i++ )
        {
            var obj = {

                value : response.data[i].id_language ,
                label : response.data[i].language
            }

            console.log( "onjeto = " , obj )

            arr.push( obj ) ;
        }


        console.log( "Se agregaron " + arr.length + " elementos")



        setOptions( arr ) ;

    })
    .catch((err)=> {


        console.log( "No llego un error =====>>>")

        console.log( err ) // Se detecto un error en la consulta

    })


} ,[])


    

  return (
    <div className='row g-0'>

        <div className='col-4'>

            <div style={{textAlign:'start'}}>
                <p style={{fontSize:'25px', textDecorationLine:'underline'}}>Idiomas Adicionales : &nbsp;&nbsp;&nbsp;</p>

            </div>

           
            
        </div>

        <div className='col-8'>
            <Select options={options} isMulti={true} onChange={onChange}></Select>
        </div>

        

    </div>
  )
}
