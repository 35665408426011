import React, { useState } from 'react';
import { useController } from 'react-hook-form';

// isRequiered, es porque en caso de ser persona moral no es necesaria la fecha de nacimiento, 
//y el colorW es para ver de que color es el warning y así solucionar el problema de que aparecía todo el tiempo, solo cambiamos el color.
const FechaNac = ({ control, name, errors, isRequired, colorW, labelName, styleLabel, validacionCom}) => {
    const {
        field: { onChange: onInputChange, value },
    } = useController({
        name,
        control,
        defaultValue: '',
    });

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    

    const handleDayChange = (e) => {
        const { value } = e.target;
        if(/^\d*$/.test(value)){
            if (value === '' || (value >= 1 && value <= 31)) {
                setDay(value);
                updateFechaNac(value, month, year);
            }
        }
    };

    const handleMonthChange = (e) => {
        const { value } = e.target;
        if(/^\d*$/.test(value)){
            if (value === '' || (value >= 1 && value <= 12)) {
                setMonth(value);
                updateFechaNac(day, value, year);
            }           
        }
    };

    const handleYearChange = (e) => {
        const { value } = e.target;
        if(/^\d*$/.test(value)){
            if (value === '' || value.length <= 4) {
                setYear(value);
                updateFechaNac(day, month, value);
            }
        } 
    };

    const updateFechaNac = (day, month, year) => {
        if(day.length === 1){
            day = "0" + day;

        }
        if (month.length === 1) {
            month = "0" + month;
        }
        const formattedDate = `${year}-${month}-${day}`;
        onInputChange(formattedDate);
    };


    const validateDate = () => {
        if ((day === '' || month === '' || year === '') && isRequired === true) {
            return colorW ? <p style={{ color: "red", fontSize: '15px', fontStyle: 'italic', textAlign: "left", display: "block"}}>Este campo es requerido.</p> : null;
        }
        
        if (value) {
            const currentDate = new Date();
            const minDate = new Date('1900-01-01');
            const eighteenYearsAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 18));
            const selectedDate = new Date(value);

            if (selectedDate < minDate) {
                return <p style={{ color: 'red', fontSize: '15px', fontStyle: 'italic', textAlign: "left", display: "block" }}>El año no puede ser antes del año 1900.</p>;
            } else if (selectedDate > eighteenYearsAgo) {
                return <p style={{ color: 'red', fontSize: '15px', fontStyle: 'italic', textAlign: "left", display: "block" }}>Debe tener al menos 18 años para poder registrarse.</p>;
            }

            if (month < 1 || month > 12) {
                return <p style={{ color: 'red', fontSize: '15px', fontStyle: 'italic', textAlign: "left", display: "block" }}>Mes inválido.</p>;
            }

            const leapYear = (year) => {
                if(year % 400 === 0){
                    return true;
                } else if(year % 100 === 0){
                    return false;
                } else if(year % 4 === 0){
                    return true;
                } else {
                    return false;
                }
            }

            const daysInMonth = {
                1: 31, 2: (leapYear(year) ? 29 : 28), 3: 31, 4: 30, 5: 31, 6: 30,
                7: 31, 8: 31, 9: 30, 10: 31, 15: 30, 12: 31
            };
            const cantidadDias = daysInMonth[month];

            if (day > cantidadDias || day < 1) {
                return <p style={{ color: 'red', fontSize: '15px', fontStyle: 'italic', display: "block", textAlign: "left" }}>Día inválido para el mes seleccionado.</p>;
            }
        }
            validacionCom.val = true
            return null;
        
    };

    return (
        <>
        <label htmlFor='fecha' style={styleLabel}>{labelName}</label>
        <div id="fecha" className="form-group" style={{ width: '100%' }}>
            <div className="input-group" >
                <input
                    type="text"
                    name="day"
                    placeholder="DD"
                    maxLength="2"
                    className="form-control rounded-0 border-0 border-bottom border-end"
                    style={{
                        ...inputStyle,
                        width: '33%',
                        textAlign: 'center',
                    }}
                    value={day}
                    onChange={handleDayChange}
                    disabled={!isRequired}
                />
                <input
                    type="text"
                    name="month"
                    placeholder="MM"
                    maxLength="2"
                    className="form-control rounded-0 border-0 border-bottom border-end"
                    style={{
                        ...inputStyle,
                        width: '33%',
                        textAlign: 'center',
                    }}
                    value={month}
                    onChange={handleMonthChange}                                 
                    disabled={!isRequired}

                />
                <input
                    type="text"
                    name="year"
                    placeholder="AAAA"
                    maxLength="4"
                    className="form-control rounded-0 border-0 border-bottom"
                    style={{
                        ...inputStyle,
                        width: '33%',
                        textAlign: 'center',
                    }}
                    value={year}
                    onChange={handleYearChange}
                    disabled={!isRequired}
                />
            </div>
            {validateDate()}
        </div>
        </>
    );
};

const inputStyle = {
    borderWidth: '1px',
    backgroundColor: '#FBFBFB',
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'center',
    borderBottomColor: 'gray',
    borderRightColor: 'gray',
    transition: 'border-color 0.2s ease-in-out',
    marginBottom: '15px',
    paddingRight: '2px',
    marginRight: '2px',
    width: '100%',
    outline: 'none',
};

export default FechaNac;
