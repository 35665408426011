//import { useEffect } from "react";
import React , {useEffect,useState,useMemo} from 'react'


import { MaterialReactTable } from 'material-react-table';


import getGlobal from '../../setglobal';


export default function UsersView( {setShowMiddle}) {
    console.log( "Entrando a usersView") ;

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
  
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });
    const [first,setFirst] = useState( 0 ) ;


    useEffect(()=>{

        setShowMiddle( false ) ;

    } , []
    )


    useEffect(() => {
        const fetchData = async () => {
          if (!data.length) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }
    
          const url = new URL(
            'users', getGlobal() 
            // process.env.NODE_ENV === 'production'
            //   ? 'https://www.material-react-table.com'
            //   : 'http://localhost:3000',
          );
          url.searchParams.set(
            'start',
            //`${ ( first )  }`,
            `${ ( pagination.pageIndex * pagination.pageSize )  }`,
    //        `${ ( pagination.pageIndex * pagination.pageSize ) + first }`,
          );
          url.searchParams.set('size', `${pagination.pageSize}`);
          //url.searchParams.set('iniDate', iniDate );
          url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
          url.searchParams.set('globalFilter', globalFilter ?? '');
          url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    
    
          console.log( url.href ) ;
    
          try {
            const response = await fetch(url.href);
            const json = await response.json();
    
            console.log( JSON.stringify( json ) )
    
            setData(json.data);
            if ( json.data.length > 0 )
            {
              console.log( "setFirst = " + json.data[0].id + " pageIndex = " + pagination.pageIndex + " pageSize = " + pagination.pageSize )
              if ( true /* first === 0 */)
              {
                setFirst( json.data[json.data.length-1].id) ;
              }
              
            }
            setRowCount(json.totalRowCount);
          } catch (error) {
            setIsError(true);
            console.error(error);
            return;
          }
          setIsError(false);
          setIsLoading(false);
          setIsRefetching(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
      ]);



      const columns = useMemo(
        () => [
          {
            accessorKey: 'id',
            header: 'Id',
          },
          {
            accessorKey: 'email',
            header: 'email',
          },      
          {
            accessorKey: 'first_name',
            header: 'Nombre',
          },
          {
            accessorKey: 'last_name',
            header: 'Apellido',
          },
    
          {
            accessorKey: 'code',
            header: 'Codigo',
          },
    
          {
            accessorKey: 'status',
            header: 'Estatus',
          },
    
          {
            accessorKey: 'created_at',
            header: 'Fecha Creación',
          },
    
    
          //column definitions...
        ],
        [],
      );
    
    

  return (
    <MaterialReactTable
    columns={columns}
    data={data}
    enableRowSelection
    getRowId={(row) => row.phoneNumber}
    initialState={{ showColumnFilters: true }}
    manualFiltering
    manualPagination
    manualSorting
    muiToolbarAlertBannerProps={
      isError
        ? {
            color: 'error',
            children: 'Error loading data',
          }
        : undefined
    }
    onColumnFiltersChange={setColumnFilters}
    onGlobalFilterChange={setGlobalFilter}
    onPaginationChange={setPagination}
    onSortingChange={setSorting}
    rowCount={rowCount}
    state={{
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    }}
  />

  )
}
