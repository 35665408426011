import React , { useState } from 'react'

// showNormal = Solicitamos regresar al estado normal
export default function LoadPhoto({showNormal, setSelectedImage}) {
    
    function preImage( val )
    {
        console.log( "preImage") ;

        setSelectedImage( val ) ;
        showNormal( false ) ;
    }

  return (
    <>
        <div>Subir Foto</div>
        <input type='file' 
          onChange={(event=>preImage(event.target.files[0]))} accept='image/*'></input>
        <button onClick={()=> showNormal(false)}style={{borderRadius :'10px'}}>Regresar</button>
    </>
    
  )
}
