import axios from 'axios'

function ApiCallGet( url  ) // Se manda data si es un p
{
    return new Promise(( resolve,reject) => {

        console.log( "Por el ApiCall get " + url ) ;


        axios.get( "http://127.0.0.1:3005" + url , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            //console.log( "LLego la respuesta " + JSON.stringify( response.data ) )
            resolve(response.data) ;
          })
          .catch((err) =>{
            console.log( err) ;
            resolve() ;
          })
          


    })


}

function ApiCallPost( url2 , data )
{


    var url = filterUrl( url2 ) ;


    console.log( "ApiCallPost url = " + url )

    //console.log( "Llamando a ApiCallPost") ;

    return new Promise(( resolve,reject) => {

        console.log( "Por el ApiCall " + JSON.stringify( data  ) ) ;





        axios.post( "http://127.0.0.1:3005" + url ,  JSON.stringify( data  )    , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            console.log( "LLego la respuesta " + response.data )
            resolve(response.data) ;
          })
          .catch((err) =>{
            console.log( err) ;
            resolve() ;
          })
          


    })



}

function filterUrl( u )
{
  var url = u.replace( "c_" , "") ; // Le quitamos el prefijo

  url = url.replace( "C_" , "" ) ;

  return url ;
}


//module.exports = { ApiCallGet , ApiCallPost } ;

export { ApiCallGet , ApiCallPost }