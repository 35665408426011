import React from "react";
import {useInView} from "react-intersection-observer";
import './FadeInLawyer.css';

export default function FadeIn() {
    const [ref1, inView1] = useInView({triggerOnce: true, threshold: 1});
    const [ref2, inView2] = useInView({triggerOnce: true, threshold: 1});
    const [ref3, inView3] = useInView({triggerOnce: true, threshold: 1})

    return (
        <div>
            <h1 style={
                {padding: "100px"}
            }></h1>
            <h1 ref={ref1}
                className={
                    `fade-in ${
                        inView1 ? 'visible' : ''
                    }`
                }
                style={
                    {
                        color: "#000000",
                        textAlign: 'left',
                        padding: '100px',
                        fontSize: "50px"
                    }
            }>
                <b>Ayuda a miles de personas que necesitan tus servicios.</b>
            </h1>
            <h1 style={
                {padding: "50px"}
            }></h1>
            <h1 ref={ref2}
                className={
                    `fade-in ${
                        inView2 ? 'visible' : ''
                    }`
                }
                style={
                    {
                        textAlign: 'right',
                        color: "#000000",
                        padding: '100px',
                        fontSize: "50px",
                        marginBottom: "150px"
                    }
            }>
                <b>Cambia paradigmas: Vuelve la justicia accesible para todos.</b>
            </h1>
            <h1 ref={ref3}
                className={
                    `fade-in ${
                        inView3 ? 'visible' : ''
                    }`
                }
                style={
                    {
                        textAlign: 'left',
                        color: "#000000",
                        padding: '100px',
                        fontSize: "50px",
                        marginBottom: "200px"
                    }
            }>
                <b>Únete a LegalTek</b>
            </h1>

        </div>
    );
}
